//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ctrlAddr, ctrlBase } from "@/controller";
import { Image, Message, MessageBox, Button, Tabs, TabPane } from "element-ui";
import { Address, Null } from "@/components";
export default {
    components: {
        [Image.name]: Image,
        [Button.name]: Button,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        Address,
        Null,
    },
    data() {
        return {
            _addrList: [], //用戶地址列表
            addrList: [], //視圖 -用戶地址列表
            storeAddressList: [], //到店地址列表
            frcList: [],
            tabs: [],
            ctrlBase,
            tabsValue: "",
            tmpFrcItem: {}, //選中的配送方式對象
        };
    },
    created() {
        this.getFrcList();
        window.getStoreAddrInfo = this.getStoreAddrInfo;
        // this.getAddrList();
    },
    methods: {
        //獲取配送方式
        async getFrcList() {
            this.frcList = await ctrlAddr.getFrcList();
            console.log(this.frcList);
            if (this.Common.isExist(this.frcList)) {
                const isStore = this.frcList.some((item) => item.isStore);
                if (isStore) await this.getStoreAddressList();
                const isNotStore = this.frcList.some((item) => !item.isStore);
                if (isNotStore) {
                    this.tabs.push({
                        name: "地址1",
                        key: "1",
                        isStore: 0,
                    });
                    await this.getAddrList();
                }

                for (const item of this.frcList) {
                    if (item.isStore) {
                        const data = { ...item, key: item.frcNbr };
                        this.tabs.push(data);
                    }
                }
                console.log("tabs");
                console.log(this.tabs);
                this.tabsValue = this.tabs[0].key;
                this.tmpFrcItem = this.tabs[0];
                // this.addrList = this._addrList.concat(this.storeAddressList);
                //地址設定
                this.setAddr(this.tabs[0]);
            }
        },
        //地址設定
        setAddr(frc) {
            frc.isStore ? (this.addrList = this.storeAddressFiter(frc.storeType)) : (this.addrList = JSON.parse(JSON.stringify(this._addrList)));
        },
        storeAddressFiter(type) {
            return JSON.parse(JSON.stringify(this.storeAddressList.filter((item) => item.storeType == type)));
        },
        //獲取外部地址信息
        getStoreAddrInfo(value) {
            console.log("-------------------------");
            console.log("獲取外部地址信息");
            console.log(value);
            const { storeName, storeAddress, storeId } = value;
            this.$refs.address.adr.city = storeName;
            this.$refs.address.adr.address = storeAddress;
            this.$refs.address.adr.cityCheck = storeId;
        },
        tabChange(item) {
            this.$refs.address.adr.city = "";
            this.$refs.address.adr.address = "";
            this.$refs.address.adr.cityCheck = "";
            this.tmpFrcItem = this.tabs[item.index];
            this.setAddr(this.tabs[item.index]);
        },
        //更新地址
        async upAddressList(tmpFrcItem) {
            if (tmpFrcItem.isStore) await this.getStoreAddressList();
            if (!tmpFrcItem.isStore) await this.getAddrList();
            this.setAddr(tmpFrcItem);
        },
        //獲取地址列表
        async getAddrList() {
            this._addrList = await ctrlAddr.getAddrList();
        },
        //獲取到店地址列表
        async getStoreAddressList() {
            this.storeAddressList = await ctrlAddr.getStoreAddressList();
        },
        //地址彈窗打開
        openAdr(status, item) {
            this.$refs.address.AddressWinVisible = true;
            this.$refs.address.status = status;
            this.$refs.address.tmpFrcItem = this.tmpFrcItem;
            if (item) {
                item.checked = item.isDefault == 1 ? true : false;
                item.cityCheck = item.cityId?.split(",");
                this.$refs.address.adr = JSON.parse(JSON.stringify(item));
            }
        },
        //刪除單條地址
        async delAdr(item) {
            const { id, isStore } = item;
            let data = null;
            MessageBox.confirm("確認刪除？", "提示", {
                confirmButtonText: "確認",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    isStore ? (data = await ctrlAddr.delStoreAddr(id)) : (data = await ctrlAddr.delAddr(id));
                    if (data) {
                        Message.success("刪除成功");
                        if (isStore) await this.getStoreAddressList();
                        if (!isStore) await this.getAddrList();
                        this.setAddr(this.tmpFrcItem);
                    }
                })
                .catch(() => {});
        },
        async setDefault(item) {
            const { id, isStore } = item;
            let data = null;
            isStore ? (data = await ctrlAddr.setStoreDefault(id)) : (data = await ctrlAddr.setDefault(id));
            if (data) {
                Message.success("設置成功");
                if (isStore) await this.getStoreAddressList();
                if (!isStore) await this.getAddrList();
                this.setAddr(this.tmpFrcItem);
            }
        },
    },
};
